/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { UserInfo } from '@/modules/campaign_management/models/CommonModels';

export const AecStage = z.enum(['INTEGRATION', 'APPROVAL', 'LIVE']);
export type AecStage = z.infer<typeof AecStage>;
export const AecStatus = z.enum(['ACTIVE', 'CANCELLED']);
export type AecStatus = z.infer<typeof AecStatus>;

export const AecChecklistStatus = z.enum(['IN_PROGRESS', 'ACTION_REQUIRED', 'COMPLETED']);
export type AecChecklistStatus = z.infer<typeof AecChecklistStatus>;

export const AecReleaseCandidate = z.object({
  id: z.string(),
  version: z.string(),
  stage: AecStage,
  lastEditedOn: z.string().optional(),
  status: AecStatus,
  description: z.string().optional(),
  vehiclePlatform: z.string().optional(),
  vehicleBrands: z.array(z.string()).optional(),
  lastEditedBy: UserInfo.optional(),
  vehicleBrandsEditedBy: UserInfo.optional(),
  vehicleBrandsEditedOn: z.string().optional(),
});

export type AecReleaseCandidate = z.infer<typeof AecReleaseCandidate>;

export const AecReleaseCandidateOverview = AecReleaseCandidate;
export type AecReleaseCandidateOverview = z.infer<typeof AecReleaseCandidateOverview>;

export const AecReleaseCandidateVersions = z.array(z.string());

export type AecReleaseCandidateVersions = z.infer<typeof AecReleaseCandidateVersions>;

export type AecReleaseCandidateRequestOverviewPayload = {
  version: string;
  description: string;
};

export type AecReleaseCandidateVehicleScopePayload = {
  vehicleBrands: string;
};

export const AecReleaseCandidateChecklistItem = z.object({
  status: AecChecklistStatus,
  date: z.coerce.date().optional(),
});

const BrandApprovalEntries = z
  .object({
    brandName: z.string(),
    confirmedBy: UserInfo.optional(),
    confirmedOn: z.coerce.date().optional(),
    confirmedOnTimeZone: z.string().optional(),
  })
  .array()
  .optional();

export type BrandApprovalEntries = z.infer<typeof BrandApprovalEntries>;

export const ScmDocumentationDocument = z.object({
  documentId: z.number(),
  name: z.string(),
  hasAttachments: z.boolean(),
});

const ScmDocumentations = z.array(ScmDocumentationDocument);
export type ScmDocumentations = z.infer<typeof ScmDocumentations>;

const DeploymentApproval = z
  .object({
    pushedBy: UserInfo.optional(),
    date: z.coerce.date().optional(),
  })
  .optional();
export type DeploymentApproval = z.infer<typeof DeploymentApproval>;

export const AecReleaseCandidateChecklist = z.object({
  id: z.string(),
  pushToApproval: AecReleaseCandidateChecklistItem.extend({
    pushedBy: UserInfo.optional(),
    conflictFlag: z.boolean(),
  }),
  releaseNotes: AecReleaseCandidateChecklistItem.extend({
    statusPerBrand: z
      .record(z.string(), z.object({ title: z.string(), url: z.string(), status: z.string() }))
      .optional(),
  }),
  sumoApproval: AecReleaseCandidateChecklistItem.extend({
    scmDocumentations: ScmDocumentations.optional(),
    functionalValidationCompletedBy: UserInfo.optional(),
    functionalValidationCompletedOn: z.coerce.date().optional(),
    brandApprovals: BrandApprovalEntries,
  }),
  deploymentRequest: AecReleaseCandidateChecklistItem.extend({
    deploymentApproval: DeploymentApproval,
    confirmedByReleaseManager: z
      .object({
        pushedBy: UserInfo.optional(),
        date: z.coerce.date().optional(),
      })
      .optional(),
    pushToLive: z
      .object({
        pushedBy: UserInfo.optional(),
        date: z.coerce.date().optional(),
      })
      .optional(),
  }),
});
export type AecReleaseCandidateChecklist = z.infer<typeof AecReleaseCandidateChecklist>;

export const AecVehicleScope = z.object({
  id: z.string(),
  version: z.string(),
  description: z.string().optional(),
  vehiclePlatform: z.string().optional(),
  vehicleBrands: z.array(z.string()).optional(),
});

export type AecVehicleScope = z.infer<typeof AecVehicleScope>;
