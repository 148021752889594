/*
 * (c) 2022 CARIAD SE, All rights reserved.
 *
 * NOTICE:
 * All the information and materials contained herein, including the intellectual and technical concepts,
 * are the property of CARIAD SE and may be covered by patents, patents in process, and are protected by trade secret and/or copyright law.
 * The copyright notice above does not evidence any actual or intended publication or disclosure of this source code, which includes information and materials
 * that are confidential and/or proprietary and trade secrets of CARIAD SE.
 * Any reproduction, dissemination, modification, distribution, public performance, public display of or any other use of this source code and/or any other
 * information and/or material contained herein without the prior written consent of CARIAD SE is strictly prohibited and in violation of applicable laws.
 * The receipt or possession of this source code and/or related information does not convey or imply any rights to reproduce, disclose or distribute its
 * contents or to manufacture, use or sell anything that it may describe in whole or in part.
 */

import { z } from 'zod';
import { ReleaseNotes } from '@/modules/campaign_management/models/CommonApprovalModels';
import { BaseCampaignOru20 } from '@/modules/campaign_management/models/CommonCampaignModels';
import { CommonSwProject, UserInfo } from '@/modules/campaign_management/models/CommonModels';

export const ZodEcuEnum = z.enum(['ICC', 'CONMOD']);

export const Artifact = z.object({
  downloadUri: z.string(),
  name: z.string(),
});
export type Artifact = z.infer<typeof Artifact>;

export const Oru20Release = CommonSwProject.extend({
  campaigns: BaseCampaignOru20.array(),
  ecu: ZodEcuEnum,
  // TODO: capybaras/948931 restore R4C logic of release notes
  // remove optional
  releaseNotes: ReleaseNotes.optional(),
  artifacts: Artifact.array(),
  originFilename: z.string(),
  releaseCount: z.number().nullable(),
  latestVersion: z.string().nullish() || null,
  owner: UserInfo.nullish() || null,
});
export type Oru20Release = z.infer<typeof Oru20Release>;

export type Oru20UpdateReleasePayload = {
  name: string;
};

export type Oru20CreateReleasePayload = Oru20UpdateReleasePayload & {
  ecu: string;
  artifactBinary: File | string;
  createCustomReleaseNotes?: boolean;
  targetLanguages?: string[];
};

export type ReleaseId = {
  id?: string;
};
